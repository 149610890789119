import { FC, useEffect, useState } from 'react';
import { Link } from 'react-scroll';

import { FaRegSmileWink, FaCode, FaFlask } from 'react-icons/fa';

export const Header: FC = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const sections = document.querySelectorAll('section[id]');

    const scrollActive = () => {
      const scrollY = window.pageYOffset;
      sections.forEach((current: any) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop + 250;
        const sectionId = current.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', scrollActive);

    return () => {
      window.removeEventListener('scroll', scrollActive);
    };
  }, []);

  return (
    <header id='header-home'>
      <div id='sidebar-header'>
        <h3 id='sidebar-title'>ISABELLE</h3>
        <h4 id='sidebar-description'>Fullstack Blockchain</h4>
        <h5 id='sidebar-description'>+4 years of experience</h5>
        <h5 id='sidebar-description'>React, Solidity and Rust.</h5>
      </div>
      <nav>
        <ul>
          <li>
            <Link
              to='presentation'
              smooth={true}
              offset={-10}
              className={`scroll ${
                activeSection === 'section1' ? 'active-link' : ''
              }`}
            >
              <div id='line-link'>
                <FaRegSmileWink /> <p>Presentation</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              to='expertise'
              spy={true}
              smooth={true}
              offset={-40}
              className={`scroll ${
                activeSection === 'section2' ? 'active-link' : ''
              }`}
            >
              <div id='line-link'>
                <FaCode /> <p>Expertise</p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              to='experience'
              spy={true}
              smooth={true}
              offset={-40}
              className={`scroll ${
                activeSection === 'section3' ? 'active-link' : ''
              }`}
            >
              <div id='line-link'>
                <FaFlask /> <p>Experience</p>
              </div>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};
