import { FC } from 'react';

import { FaHandSpock } from 'react-icons/fa';

export const Presentation: FC = () => {
  return (
    <section id='presentation'>
      <div id='content-presentation'>
        <FaHandSpock
          className='spock-icon'
          id='icon-hand-first'
          color='#7F8A79'
        />
        <blockquote>
          <p>
            Hi,
            <br />
            I'm a fullstack blockchain developer with experience in smart
            contracts for Ethereum and Solana. I also have 4+ years of
            experience in frontend development, creating clean and user-friendly
            interfaces. I enjoy working independently and always aim to build
            high-quality products that connect blockchain technology with great
            user experiences.
          </p>
        </blockquote>
        <FaHandSpock
          className='spock-icon'
          id='icon-hand-second'
          color='#7F8A79'
        />
        <div className='border-presentation' id='cube-1' />
        <div className='border-presentation' id='cube-2' />
      </div>
    </section>
  );
};
