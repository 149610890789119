import { FC } from 'react';
import PartitionChart from '../commons/PartitionChart';
import Carousel from '../commons/Carousel';

export const Expertise: FC = () => {
  return (
    <section id='expertise'>
      <div className='title-section'>
        <div>
          <h2 className='title' data-text='EXPERTISE'>
            EXPERTISE
          </h2>
        </div>
      </div>
      <div className='infos-section'>
        <p>
          I enjoy working on challenging and innovative projects, as it allows
          me to learn new things.
        </p>
      </div>
      <div id='boxes-expertise'>
        <div className='box-expertise'>
          <div className='wrapper-giff'>
            <iframe
              title='Giff of hacker'
              src='https://giphy.com/embed/RbDKaczqWovIugyJmW'
              className='giphy-embed'
              allowFullScreen
            ></iframe>
          </div>
          <h3>DEVELOPMENT WEB</h3>
          <ul>
            <li>
              Creation of an IHM in pixel-perfect order, with integration of a
              creative and modern graphic model.{' '}
            </li>
            <li>
              Compliance with the latest W3C standards, responsive design,
              optimization and performance improvement.{' '}
            </li>
            <li>Creation and connection to an API. </li>
            <li>Relational database skills.</li>
            <li>Implementation of unit tests acceptance.</li>
          </ul>
        </div>
        <div className='box-expertise'>
          <div className='wrapper-giff'>
            <iframe
              title='Giff of a bear in 3D who walk'
              src='https://giphy.com/embed/9Iwn9QqdjF1hm'
              className='giphy-embed'
              allowFullScreen
            ></iframe>
          </div>
          <h3>WEBGL</h3>
          <ul>
            <li>Writing shaders in GLSL and knowledge of 3D mathematics.</li>
            <li>Use of the Three.js library. </li>
            <li>Creation of a scene with loads of 3D objects.</li>
            <li>
              Control of the animation system (mixer, rettargeting and cloning)
              knowledge about rendering a 3D scene in a browser, as well as
              solid foundations of Blender 3D engine.
            </li>
          </ul>
        </div>
        <div className='box-expertise'>
          <div className='wrapper-giff'>
            <iframe
              title="Giff of the video game Axies's parodie "
              src='https://giphy.com/embed/olz53TmssVy8ApW7oB'
              className='giphy-embed'
              allowFullScreen
            ></iframe>
          </div>
          <h3>BLOCKCHAIN</h3>
          <ul>
            <li>
              Understanding of how the Ethereum blockchain works and its
              environment.
            </li>
            <li>
              Introduction to the fundamentals of the solidity language and the
              design of smart contracts.
            </li>
            <li>Development of a dapp on Ethereum.</li>
            <li>Realization and implementation of a DeFi product.</li>
          </ul>
        </div>
      </div>
      <div id='donut'>
        <div className='subTitle-section'>
          <h3>Skills</h3>
        </div>
        <div id='datas-skills'>
          <p id='text-center-donut'></p>
          <PartitionChart />
        </div>
      </div>
      <div id='slider'>
        <h3>MY SKILLS</h3>
        <div id='arrows'>
          <button aria-label='to the left' id='leftArrow'>
            <i className='fas fa-chevron-left'></i>
          </button>
          <p>
            <span id='index-skills'></span> /<span id='length-skills'></span>
          </p>
          <button aria-label='to the right' id='rightArrow'>
            <i className='fas fa-chevron-right'></i>
          </button>
        </div>
        <Carousel />
      </div>
    </section>
  );
};
